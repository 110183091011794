<template>
  <el-form ref="rateForm" label-width="200px" :model="props.methods">
    <template v-for="(method,index) in props.methods" :key="method">
      <el-card style="margin-top:25px">
        <template #header>
          {{method.name}}
        </template>
        <template v-if="method.code=='alipay' || method.code == 'wxpay' || method.code == 'yzfpay' || method.code == 'recharge'">
          <el-row>
            <el-col :span="22" :offset="1">
              <el-form-item label="费率:" :rules="[{ required: true, message: '此项必填' }]">
                <el-input type="number" step="0.01" max="100.00" min="0.00" v-model="method.pivot.cost">
                  <template #suffix>%</template>
                </el-input>
                <span class="tips-text tips">渠道支付方式成本：</span><span class="tips-number tips">{{method.cost}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <template v-if="isShowExtraCost">
            <template v-if="method.pivot.extra_cost">
              <el-row>
                <el-col :span="22" :offset="1">
                  <el-form-item label="额外收取下级分润比例:" :rules="[{ required: true, message: '此项必填' }]">
                    <el-input type="number" step="1" max="100.00" min="0.00" v-model="methodForm[index]['extra_cost']">
                      <template #suffix>%</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </template>
            <template v-else>
              <el-row>
                <el-col :span="22" :offset="1">
                  <el-form-item label="额外收取下级分润比例:" :rules="[{ required: true, message: '此项必填' }]">
                    <el-input type="number" step="1" max="100.00" min="0.00" v-model="methodForm[index]['extra_cost']">
                      <template #suffix>%</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </template>
          </template>
        </template>
        <template v-if="method.code == 'unionpay'">
          <el-row>
            <el-col :span="13" :offset="1">
              <el-form-item label="借记卡 1000.00 以下(含):" :rules="[{ required: true, message: '此项必填' }]">
                <el-input type="number" step="0.01" max="100.00" min="0.00" v-model="method.pivot.cost.debitcard.cost">
                  <template #suffix>%</template>
                </el-input>
                <span class="tips-text tips">渠道支付方式成本：</span><span class="tips-number tips">{{method.cost.debitcard.cost}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label-width="10" :rules="[{ required: true, message: '此项必填' }]">
                <el-input type="number" step="0.01" max="100.00" min="0.00" v-model="method.pivot.cost.debitcard.total" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="13" :offset="1">
              <el-form-item label="借记卡 1000.00 以上:" :rules="[{ required: true, message: '此项必填' }]">
                <el-input type="number" step="0.01" max="100.00" min="0.00" v-model="method.pivot.cost.debitcard_outer.cost">
                  <template #suffix>%</template>
                </el-input>
                <span class="tips-text tips">渠道支付方式成本：</span><span class="tips-number tips">{{method.cost.debitcard_outer.cost}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label-width="10">
                <el-input type="number" step="0.01" max="100.00" min="0.00" v-model="method.pivot.cost.debitcard_outer.total" :rules="[{ required: true, message: '此项必填' }]" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="13" :offset="1">
              <el-form-item label="信用卡 1000.00 以下(含):" :rules="[{ required: true, message: '此项必填' }]">
                <el-input type="number" step="0.01" max="100.00" min="0.00" v-model="method.pivot.cost.creditcard.cost"><template #suffix>%</template>
                </el-input>
                <span class="tips-text tips">渠道支付方式成本：</span><span class="tips-number tips">{{method.cost.creditcard.cost}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="13" :offset="1">
              <el-form-item label="信用卡 1000.00 以上:" :rules="[{ required: true, message: '此项必填' }]">
                <el-input type="number" step="0.01" max="100.00" min="0.00" v-model="method.pivot.cost.creditcard_outer.cost" :rules="[{ required: true, message: '此项必填' }]"><template #suffix>%</template>
                </el-input>
                <span class="tips-text tips">渠道支付方式成本：</span><span class="tips-number tips">{{method.cost.creditcard_outer.cost}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <template v-if="method.pivot.extra_cost">
            <el-row>
              <el-col :span="22" :offset="1">
                <el-form-item label="额外收取下级分润比例:" :rules="[{ required: true, message: '此项必填' }]">
                  <el-input type="number" step="0.01" max="100.00" min="0.00" v-model="methodForm[index]['extra_cost']">
                    <template #suffix>%</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
          <template v-else>
            <el-row>
              <el-col :span="22" :offset="1">
                <el-form-item label="额外收取下级分润比例:" :rules="[{ required: true, message: '此项必填' }]">
                  <el-input type="number" step="1" max="100.00" min="0.00" v-model="methodForm[index]['extra_cost']">
                    <template #suffix>%</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </template>
        <template v-if="method.code == 'bankcard'">
          <el-row>
            <el-col :span="13" :offset="1">
              <el-form-item label="借记卡:" :rules="[{ required: true, message: '此项必填' }]">
                <el-input type="number" step="0.01" max="100.00" min="0.00" v-model="method.pivot.cost.debitcard.cost"><template #suffix>%</template>
                </el-input>
                <span class="tips-text tips">渠道支付方式成本：</span><span class="tips-number tips">{{method.cost.debitcard.cost}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label-width="10" :rules="[{ required: true, message: '此项必填' }]">
                <el-input type="number" step="0.01" max="100.00" min="0.00" v-model="method.pivot.cost.debitcard.total" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="13" :offset="1">
              <el-form-item label="信用卡:" :rules="[{ required: true, message: '此项必填' }]">
                <el-input type="number" step="0.01" max="100.00" min="0.00" v-model="method.pivot.cost.creditcard.cost"><template #suffix>%</template>
                </el-input>
                <span class="tips-text tips">渠道支付方式成本：</span><span class="tips-number tips">{{method.cost.creditcard.cost}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <template v-if="method.pivot.extra_cost">
            <el-row>
              <el-col :span="22" :offset="1">
                <el-form-item label="额外收取下级分润比例:" :rules="[{ required: true, message: '此项必填' }]">
                  <el-input type="number" step="1" max="100.00" min="0.00" v-model="methodForm[index]['extra_cost']">
                    <template #suffix>%</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
          <template v-else>
            <el-row>
              <el-col :span="22" :offset="1">
                <el-form-item label="额外收取下级分润比例:" :rules="[{ required: true, message: '此项必填' }]">
                  <el-input type="number" step="1" max="100.00" min="0.00" v-model="methodForm[index]['extra_cost']">
                    <template #suffix>%</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </template>
      </el-card>
    </template>
  </el-form>
</template>

<script setup>
import { ref, defineProps, defineExpose, getCurrentInstance } from 'vue'

const { proxy } = getCurrentInstance() // 当前实例

const props = defineProps({
  methods: Object,
  isShowExtraCost: {
    type: Boolean,
    default: true
  }
})

const methodForm = ref([])

props.methods.forEach(method => {
  const nMethod = {
    extra_cost: method.extra_cost ? method.extra_cost : 0
  }
  methodForm.value.push(nMethod)
})

const formatForm = () => {
  methodForm.value = []

  props.methods.forEach(method => {
    const nMethod = {
      extra_cost: method.extra_cost ? method.extra_cost : 0
    }
    methodForm.value.push(nMethod)
  })
}

const sendMethodForm = () => {
  proxy.$refs.rateForm.validate(validate => {
    console.log(validate)
  })
  let methods = []
  for (let i = 0; i < props.methods.length; i++) {
    // if (!props.methods[i].pivot.cost) {
    //     return false
    // }

    const nMethod = {
      id: props.methods[i].id,
      cost: props.methods[i].pivot.cost,
      extra_cost: methodForm.value[i].extra_cost
    }
    methods.push(nMethod)
  }

  return methods
}

defineExpose({ formatForm, sendMethodForm })
</script>

<style lang="less">
.tips {
  font-size: 10px;
  color: rgba(150, 150, 150, 0.7);
}

.tips-number {
  color: red;
}
</style>